var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row align-items-center"},[_c('validationProvider',{staticClass:"col-md-12 px-0",attrs:{"id":"supInput","tag":"div","rules":"required","name":"Nome do Suporte","disabled":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('label',{staticClass:"label_default my-3",class:classes},[_vm._v("Nome do suporte")]),_c('input',{staticClass:"input_default form-control",class:classes,attrs:{"type":"text"},domProps:{"value":_vm.name}})]}}])}),_c('validationProvider',{staticClass:"col-md-6 pl-0 mt-3",attrs:{"id":"supInput","tag":"div","rules":"required","name":"Fornecedor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('label',{staticClass:"label_default my-3",class:classes},[_vm._v("Fornecedor")]),_c('input',{staticClass:"input_default form-control",class:classes,attrs:{"type":"text","disabled":""},domProps:{"value":_vm.supplier}})]}}])}),_c('validationProvider',{staticClass:"col-md-6 pr-0 mt-3",attrs:{"id":"supInput","tag":"div","rules":"required","name":"Nome do atendente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('label',{staticClass:"label_default my-3",class:classes},[_vm._v("Nome do atendente")]),_c('input',{staticClass:"input_default form-control",class:classes,attrs:{"type":"text","disabled":""},domProps:{"value":_vm.clerk}})]}}])}),_c('validationProvider',{staticClass:"col-md-6 pl-0",attrs:{"id":"supInput","tag":"div","rules":"required","name":"Telefone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('label',{staticClass:"label_default my-3",class:classes},[_vm._v("Telefone")]),_c('input',{staticClass:"input_default form-control",class:classes,attrs:{"type":"number","disabled":""},domProps:{"value":_vm.phone}})]}}])}),_c('validationProvider',{staticClass:"col-md-6 pr-0",attrs:{"id":"supInput","tag":"div","rules":"required","name":"Telefone secundário"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('label',{staticClass:"label_default my-3",class:classes},[_vm._v("Telefone secundário")]),_c('input',{staticClass:"input_default form-control",class:classes,attrs:{"type":"number","disabled":""},domProps:{"value":_vm.secondaryPhone}})]}}])}),_c('validationProvider',{staticClass:"col-md-12 px-0",attrs:{"id":"supInput","tag":"div","rules":"required","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('label',{staticClass:"label_default my-3",class:classes},[_vm._v("email")]),_c('input',{staticClass:"input_default form-control",class:classes,attrs:{"type":"text","disabled":""},domProps:{"value":_vm.email}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }